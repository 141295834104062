<template>
  <v-container fluid>
    <v-row class="pt-4" no-gutters>
      <v-col class="mr-8" cols="5">
        <v-card class="pa-5"
          ><v-form ref="inviteForm" v-model="valid">
            <div>
              <h2 class="text-subtitle-1 font-weight-bold mr-4">
                {{ $t("systemSettings.tabs.companyInvite") }}
              </h2>
            </div>
            <div>
              <v-text-field
                v-model="form.email"
                outlined
                dense
                single-line
                validate-on-blur
                :rules="emailRules"
              ></v-text-field>
              <v-btn
                color="primary"
                :disabled="submitLoading"
                :loading="submitLoading"
                @click="submitHandle"
              >
                {{ $t("companyInvite.submit") }}
              </v-btn>
            </div></v-form
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Service from '@/services'
export default {
  name: 'CompanyInvite',
  data () {
    return {
      form: {
        email: ''
      },
      valid: true,
      submitLoading: false,
      emailRules: [
        v => !!v.trim() || this.$t('validation.emailRequired'),
        v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || this.$t('validation.emailInvalidFormat')
      ]
    }
  },
  methods: {
    async submitHandle () {
      this.$refs.inviteForm.validate()
      if (!this.valid) {
        return
      }
      this.submitLoading = true
      try {
        await Service.post('CustomerService/InviteUser', this.form)
        this.$refs.inviteForm.resetValidation()
        this.form = Object.assign(
          {},
          {
            email: ''
          }
        )
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('companyInvite.succes')
        )
      } catch (e) {}
      this.submitLoading = false
    }
  }
}
</script>
